@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700;900&display=swap");
html, body {
  height: 100%;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700; }
  html p, html a, html span, body p, body a, body span {
    color: #fff; }

body {
  background: #1e1e1e;
  position: relative; }

img, svg {
  width: 100%; }

nav {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
  z-index: 999999;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Barlow', sans-serif; }
  nav .menu-inner {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
    padding: 50px 30px; }
    @media only screen and (max-height: 500px) {
      nav .menu-inner {
        position: static;
        transform: none; } }
  nav .menu-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1100px;
    margin: auto; }
  nav .menu-list {
    text-align: left;
    padding-left: 7.27273%;
    border-left: #fff solid 1px; }
    nav .menu-list li:not(:last-child) {
      padding-bottom: 38px; }
    nav .menu-list a {
      font-size: 60px;
      font-weight: 500; }
  nav .menu-logo {
    width: 400px;
    margin-right: 12.72727%; }
  nav .menu-close {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
    background: #000;
    padding: 33px; }
    nav .menu-close div {
      position: relative; }
    nav .menu-close span {
      position: absolute;
      top: 17px;
      left: -5px;
      display: block;
      width: 45px;
      height: 2px;
      background-color: #ffffff;
      z-index: 99999999;
      border-radius: 3px; }
      nav .menu-close span:nth-child(1) {
        transform: rotate(45deg); }
      nav .menu-close span:nth-child(2) {
        transform: rotate(-45deg); }
    nav .menu-close:hover {
      opacity: 0.6;
      transition: all 0.2s linear; }

.lang-switcher {
  position: absolute;
  right: 0px;
  top: 20px;
  font-size: 20px;
  z-index: 10; }
  .lang-switcher a {
    padding: 0 20px;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 0.8;
    display: inline-block;
    font-weight: 400;
    padding-bottom: 2px;
    font-family: 'Barlow', sans-serif; }
    .lang-switcher a.jp {
      color: #4d4d4d;
      border-right: #fff 1px solid; }

.menu-open,
.menu-open span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box; }

.menu-open {
  position: fixed;
  top: 40px;
  left: 30px;
  z-index: 10000; }
  .menu-open div {
    position: relative;
    width: 40px;
    height: 24px; }

.menu-open span {
  position: absolute;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: #fff; }

.menu-open span:nth-of-type(1) {
  top: 0; }

.menu-open span:nth-of-type(2) {
  top: 11px; }

.menu-open span:nth-of-type(3) {
  bottom: 0; }

main {
  overflow-x: hidden;
  background-color: #000; }

section {
  overflow: hidden; }
  section.bg-black .section-inner {
    background-color: #000; }
    section.bg-black .section-inner::after {
      background: #000; }
  section.bg-gray .section-inner {
    background-color: #1e1e1e; }
    section.bg-gray .section-inner::after {
      background: #1e1e1e; }
  section .section-side, section .section-accent {
    position: absolute; }
  section .section-accent {
    right: 0; }
  section .section-side {
    left: 0;
    top: 50%;
    transition: all 0.5s; }
  section .section-inner h2 {
    text-align: right;
    transition: all 0.5s;
    opacity: 0;
    transform: translateX(30%) skew(0deg, 5deg); }
    section .section-inner h2 span {
      display: block;
      line-height: 1; }
    section .section-inner h2 .titleEn {
      font-family: 'Barlow', sans-serif;
      font-weight: 600; }
  section .scrollIn {
    opacity: 0;
    transition: opacity 0.5s; }
  section.animate .scrollIn {
    opacity: 1; }
  section.animate h2 {
    opacity: 1;
    transform: translateX(0%) skew(0deg, 5deg); }
  section.animate .section-side {
    left: 0;
    top: 50%; }

.section-inner {
  transform: skew(0deg, -5deg);
  width: 100%;
  margin: auto;
  position: relative;
  overflow: hidden; }

.section-inner > * {
  transform: skew(0deg, 5deg); }

.animElem.fadeIn {
  opacity: 0; }

.fadeIn {
  transition: opacity 0.3s;
  transition-delay: 0.5s !important;
  opacity: 1; }

section.top {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 0;
  margin-top: 0;
  background-color: #000;
  width: 100%;
  height: 100vh; }
  section.top .section-inner {
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center; }
  section.top:after {
    content: none; }
  section.top h1 {
    margin: auto;
    opacity: 0;
    transform: skew(0deg, 5deg);
    transition: all 2s; }
    section.top h1.animate {
      opacity: 1;
      transform: skew(0deg, 5deg); }

section.games {
  z-index: 2; }
  section.games img {
    display: block; }
  section.games.animate .about-name img {
    opacity: 1; }
  section.games.animate .about-name img:nth-of-type(1) {
    transform: translateX(0%); }
  section.games.animate .about-name img:nth-of-type(2) {
    transform: translateX(0%); }

.section-content {
  display: flex;
  justify-content: center;
  align-items: center; }
  .section-content h3 {
    color: #fff;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    letter-spacing: 0.2rem; }
    .section-content h3:after {
      content: '';
      height: 1px;
      background: #4b4b4b;
      display: block; }

section.life .life-coming {
  font-family: 'Barlow', sans-serif;
  font-weight: 500; }

.eng main section {
  font-family: 'Barlow', sans-serif;
  font-weight: 400;
  letter-spacing: 0.05rem; }
  .eng main section h2 {
    width: 100%;
    text-align: right;
    padding-right: 0; }

.eng main section.about .about-lead, .eng main section.about .about-name {
  width: 100%;
  text-align: left;
  font-weight: 500; }

.eng main section.about .about-name img {
  margin-left: 0; }

.eng main section.label {
  font-weight: 500; }
  .eng main section.label .label-description {
    width: 100%;
    margin: auto; }
    .eng main section.label .label-description a {
      margin: 0; }

.eng .lang-switcher a.jp {
  color: #fff; }

.eng .lang-switcher a.en {
  color: #4d4d4d !important; }

.footer {
  background: #1e1e1e; }
  .footer .links {
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    letter-spacing: 0.05rem; }
    .footer .links a {
      line-height: 1;
      display: inline-block; }
      .footer .links a:first-child {
        border-right: 1px solid #fff; }
  .footer p.copy {
    font-family: 'Barlow', sans-serif;
    letter-spacing: 0.05rem;
    font-weight: 500; }

.go-backto-top {
  display: none;
  width: 20px;
  height: 20px;
  color: #fff;
  border-top: solid 2px;
  border-right: solid 2px;
  transform: rotate(-45deg);
  position: fixed;
  bottom: 50px;
  right: 50px;
  margin: auto; }

* {
  margin: 0;
  padding: 0;
  font-size: 100%;
  box-sizing: border-box; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0; }

img {
  border: 0; }

ul, ol {
  list-style-type: none;
  text-indent: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th {
  font-style: normal;
  font-weight: normal;
  text-align: left; }

em, strong {
  font-style: normal;
  font-weight: normal; }

cite {
  font-style: normal; }

a {
  outline: none;
  text-decoration: none; }

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none; }

@media only screen and (max-width: 767px) {
  html {
    font-size: 2.6666666vw;
    -webkit-text-size-adjust: 100%; }
  .forP {
    display: none !important; }
  .lang-switcher {
    position: fixed;
    z-index: 1;
    top: 1.4rem;
    font-size: 1.6rem; }
    .lang-switcher a {
      padding: 0 1.5rem;
      letter-spacing: 1px;
      font-size: 1.6rem !important;
      font-weight: 400;
      display: inline-block;
      line-height: 0.7; }
      .lang-switcher a.jp {
        padding-left: 0;
        color: #4d4d4d;
        border-right: #fff 1px solid;
        padding-bottom: 0.2rem; } }

@media only screen and (max-width: 767px) and (orientation: landscape) {
  nav {
    overflow-y: scroll; } }

@media only screen and (max-width: 767px) {
  nav .menu-inner {
    padding: 0 2.5rem; } }
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    nav .menu-inner {
      position: relative;
      transform: none; } }

@media only screen and (max-width: 767px) {
  nav .menu-content {
    display: block;
    max-width: none; }
  nav .menu-list {
    border: none;
    padding: 2.5rem 0 0; } }
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    nav .menu-list {
      padding: 2.5rem 0 6.75rem; } }

@media only screen and (max-width: 767px) {
    nav .menu-list ul {
      padding-left: 2rem;
      border-left: #fff solid 2px; }
    nav .menu-list li {
      line-height: 2rem; }
      nav .menu-list li a {
        font-size: 4rem;
        display: block;
        line-height: 0.7; }
    nav .menu-list li:not(:last-child) {
      padding-bottom: 2rem; }
    nav .menu-list li:nth-child(5) a {
      line-height: 3.5rem; }
    nav .menu-list a {
      font-size: 4rem; }
  nav .menu-logo {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 12.1rem;
    padding-right: 0;
    margin-right: 0; }
  nav .menu-close {
    width: 5rem;
    height: 5rem;
    padding: 1.3rem; }
    nav .menu-close span {
      top: 1.1rem;
      left: 0.25rem;
      width: 2rem;
      height: 0.1rem; }
  nav .lang-switcher {
    position: static;
    margin-top: 7rem; }
  .menu-open {
    position: fixed;
    top: 2rem;
    left: 1.5rem;
    z-index: 10000; }
    .menu-open div {
      width: 2rem;
      height: 1.25rem; }
    .menu-open span {
      height: 0.1rem; }
    .menu-open span:nth-of-type(2) {
      top: 0.55rem; }
  main section {
    padding: 4rem 0 5rem;
    margin-top: -5rem; }
    main section .section-inner {
      padding: 7rem 0;
      transform: skew(0deg, -7deg); }
    main section .section-inner > * {
      transform: skew(0deg, 7deg); }
    main section .section-side {
      width: 10.5rem;
      z-index: 0;
      top: 50%;
      transform: translateY(100%) skew(0deg, 7deg); }
    main section .section-accent {
      top: -4.5vw;
      width: 25rem; }
    main section .titleEn {
      font-size: 1.2rem;
      padding-bottom: 1rem; }
      main section .titleEn:before {
        content: '';
        display: inline-block;
        width: 3rem;
        height: 1px;
        background-color: #fff;
        vertical-align: middle;
        margin-right: 0.6rem; }
    main section .titleJp {
      font-size: 1.8rem; }
    main section .section-inner h2 {
      padding-right: 2rem;
      margin-bottom: 5.5rem;
      transform: translateX(30%) skew(0deg, 7deg); }
    main section.animate h2 {
      transform: translateX(0%) skew(0deg, 7deg); }
    main section.animate.animate .section-side {
      opacity: 1;
      transform: translateY(-45%) skew(0deg, 7deg); }
  main section.top {
    height: 100vh; }
    main section.top h1 {
      width: 25.4rem;
      transform: skew(0deg, 7deg) scale(0.3); }
      main section.top h1.animate {
        opacity: 1;
        transform: skew(0deg, 7deg) scale(1); }
  main section.games h2 {
    margin: 0 auto 3rem; }
  main section.life {
    font-size: 3rem; }
    main section.life .section-inner {
      padding: 5rem 0 16rem;
      text-align: center; }
      main section.life .section-inner h2 {
        margin-bottom: 6.5rem; }
  main .section-content img {
    width: 14rem; }
  main .section-content div {
    margin-left: 1.7rem;
    text-align: left; }
    main .section-content div p {
      font-size: 1rem; }
    main .section-content div h3 {
      font-size: 3.6rem; }
      main .section-content div h3:after {
        width: 16.7rem;
        margin-top: 1rem; }
  main .section-content a {
    display: block;
    width: 9.25rem;
    margin-top: 3.75rem; }
    main .section-content a img {
      width: 100%; }
  main section.entame {
    padding: 0.5rem 0 3.5rem;
    margin-top: -5rem; }
    main section.entame .section-content {
      display: block; }
      main section.entame .section-content > img {
        width: 34rem; }
      main section.entame .section-content h3 {
        letter-spacing: -0.05rem; }
      main section.entame .section-content div {
        margin-top: 4rem; }
        main section.entame .section-content div h3:after {
          width: 19rem;
          margin-top: 1rem; }
    main section.entame .section-inner {
      padding: 5rem 0;
      transform: skew(0deg, -7deg); }
  main section.about {
    font-size: 1.4rem;
    line-height: 2;
    padding: 4rem 0 6rem; }
    main section.about .section-inner {
      padding: 7rem 0 6.5rem; }
    main section.about .about-lead {
      width: 29.5rem;
      margin: auto;
      text-align: left; }
      main section.about .about-lead p {
        margin-bottom: 2rem; }
  .footer {
    transform: skew(0deg, -5deg);
    margin-top: -5rem; }
    .footer .section-inner {
      padding: 7rem 0px 0px;
      transform: none; }
    .footer .links {
      font-size: 1.2rem;
      margin-bottom: 2.5rem;
      text-align: center; }
      .footer .links a {
        padding: 0 1rem; }
    .footer p.copy {
      padding-bottom: 4rem;
      font-size: 1rem;
      text-align: center; }
  .go-backto-top {
    width: 1.5rem;
    height: 1.5rem;
    bottom: 2.5rem;
    right: 2.5rem;
    border-width: 1px;
    margin: auto; }
  .eng main section h2 {
    padding-left: 3rem;
    margin: 0 auto 5rem;
    transform: translateX(50%) skew(0deg, 7deg); }
  .eng main .section-inner {
    padding: 7.3rem 0 9rem; }
  .eng main section.games h2 {
    margin: 0 auto 2rem; }
  .eng main section.about .section-inner {
    padding: 7.3rem 0 9rem; }
  .eng main section.about .about-lead {
    width: 22.25rem; }
  .eng main .links a {
    padding: 0 2rem; }
  .eng section.animate .section-inner h2 {
    transform: translateX(0%) skew(0deg, 7deg); }
  .eng section.animate .section-inner.animate .section-side {
    opacity: 1;
    transform: translateY(-45%) skew(0deg, 7deg); } }

@media only screen and (min-width: 768px) {
  .forS {
    display: none !important; }
  body {
    margin: 0 auto;
    text-align: center; }
    body.desktop a, body.desktop button, body.desktop a:hover span {
      transition: opacity 0.3s;
      cursor: pointer; }
    body.desktop a:hover, body.desktop a:hover button, body.desktop a:hover span {
      opacity: 0.7; }
    body.desktop a {
      transition: opacity .5s; }
      body.desktop a:hover {
        cursor: pointer;
        opacity: .6; }
  .menu-list a {
    line-height: 38px; }
  main section {
    padding: 140px 0px 215px;
    margin-top: -356px; }
    main section .section-side {
      width: 140px;
      opacity: 0;
      transform: translateY(100%) skew(0deg, 5deg); }
    main section .section-inner {
      padding: 140px 20px 215px; }
    main section .section-accent {
      max-width: 800px;
      top: -4.5vw;
      width: 800px; }
    main section .titleEn {
      font-size: 16px;
      padding-bottom: 15px; }
      main section .titleEn:before {
        content: '';
        display: inline-block;
        width: 40px;
        height: 1px;
        background-color: #fff;
        vertical-align: middle;
        margin-right: 12px; }
    main section .titleJp {
      font-size: 24px; }
    main section .section-inner h2 {
      padding-right: 100px;
      margin-bottom: 100px; }
    main section.animate .section-side {
      opacity: 1;
      transform: translateY(-45%) skew(0deg, 5deg); }
  main section.top h1 {
    width: 600px; }
  main section a.more {
    display: block;
    width: 135px;
    margin-top: 75px; }
    main section a.more img {
      width: 100% !important; }
  main .section-content img {
    width: 280px; }
  main .section-content div {
    margin-left: 5.71429vw;
    text-align: left; }
    main .section-content div p {
      font-size: 14px; }
    main .section-content div h3 {
      font-size: 60px; }
      main .section-content div h3:after {
        width: 320px;
        margin-top: 5px; }
  main section.entame .section-content > img {
    width: 57.14286vw;
    margin-left: -9%; }
  main section.entame .section-content h3 {
    letter-spacing: -1.5px; }
  main section.entame .section-content div {
    margin-left: -9%; }
  main section.life {
    font-size: 48px;
    margin-top: -2.85714%; }
    main section.life .section-inner {
      padding: 140px 20px 360px; }
      main section.life .section-inner h2 {
        margin-bottom: 190px; }
  main section.about {
    font-size: 20px;
    line-height: 2;
    padding: 140px 0px 0px; }
    main section.about .about-lead {
      width: 400px;
      margin: auto;
      text-align: left; }
      main section.about .about-lead p {
        margin-bottom: 30px; }
  main section .section-inner {
    padding: 140px 20px 255px; }
  .footer {
    transform: skew(0deg, -5deg);
    margin-top: -100px; }
    .footer .section-inner {
      padding: 100px 0px 0px;
      transform: skew(0deg, 5deg); }
      .footer .section-inner > * {
        transform: none; }
    .footer .links {
      font-size: 14px;
      margin-bottom: 30px; }
      .footer .links a {
        padding: 0 20px; }
    .footer p.copy {
      padding-bottom: 10px;
      font-size: 11px; }
  .eng main section h2 {
    margin: -30px auto 100px;
    transform: translateX(50%) skew(0deg, 7deg);
    padding-right: 80px; }
  .eng main section.games .section-content div p {
    font-size: 20px; }
  .eng main section.life {
    padding: 8rem 0 10rem; }
  .eng main section.about .about-lead {
    max-width: 800px;
    margin: auto;
    padding-left: 200px; }
  .eng main section.animate .section-inner h2 {
    transform: translateX(0%) skew(0deg, 5deg); }
  .eng main section.animate .section-inner.animate .section-side {
    opacity: 1;
    transform: translateY(-45%) skew(0deg, 5deg); } }
